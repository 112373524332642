<template>
  <div>
    <LayoutManager v-if="isAuthenticated">
      <div class="h-100 lxp-badge" id="qa-certificate-view">
        <CertificateWrapper />
      </div>
    </LayoutManager>
    <LandingPageLayout v-else>
      <div class="h-100 lxp-badge" id="qa-certificate-view">
        <CertificateWrapper />
      </div>
    </LandingPageLayout>
  </div>
</template>
<script>
import LayoutManager from "@/components/Layout/LayoutManager";
import LandingPageLayout from "@/components/Layout/LandingPageLayout.vue";

import CertificateWrapper from "@/components/Achievements/CertificateWrapper.vue";

export default {
  name: "CertificateView",
  components: {
    LayoutManager,
    LandingPageLayout,
    CertificateWrapper
  },
  computed: {
    isAuthenticated() {
      return this.$keycloak.authenticated;
    }
  },
  created() {
    this.$emit("update:layout", "main");
    if (this.$route.query.lang) {
      if (this.allLanguage?.find(l => l.id === this.$route.query.lang)) {
        this.language = this.$route.query.lang;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.lxp-badge {
  padding-top: 18px;
}
</style>
