<template>
  <LayoutManager>
    <div class="certificate-wrapper">
      <div>
        <div v-if="isLoading" class="h-300">
          <Loader />
        </div>
        <b-row
          class="certificate-wrapper--div"
          :class="{ 'is-public': !isAuthenticated }"
          v-else
        >
          <b-col
            cols="12"
            class="credential__breadcrumb--margin"
            v-if="isAuthenticated"
          >
            <LxpBreadcrumb
              :items="setBreadcrumbsPath"
              @click="navigateToLink"
            />
          </b-col>

          <b-col
            cols="2"
            class="d-md-flex justify-content-center d-none"
            v-if="isAuthenticated"
          >
            <div @click="goBack()" class="details_back_button c-pointer">
              <img
                class="details-icons"
                :src="require(`@/assets/images/my-achievements/back.svg`)"
                alt="back"
              />
            </div>
          </b-col>

          <b-col cols="12" lg="7" md="7" class="p-0 cw-main">
            <div class="cw-main__heading" v-if="!isAuthenticated">
              <label>{{ $t("my_achievements.course_certification") }}</label>
              <h3 @click="courseTitleClick">
                {{
                  awardType === "credential"
                    ? courseCredential?.course_title
                    : certificateDetail.title
                }}
              </h3>
            </div>
            <Certificate v-if="awardType === 'certificate'" />
            <ConsolidatedCertificate v-else-if="awardType === 'consolidated'" />
            <Credential
              v-else-if="awardType === 'credential'"
              :showError="showError"
              :courseCredential="courseCredential"
            ></Credential>
          </b-col>
          <b-col
            cols="3"
            :class="[
              'credential__card-operation d-md-flex d-none',
              { disabled: showError }
            ]"
            v-if="isAuthenticated && !isShareAward"
          >
            <div
              class="cc__cta--download awards-cta--download"
              @click="onDownload"
            >
              <LxpLoader v-if="isDownloadLoading" />
              <img
                src="@/assets/images/my-achievements/download.svg"
                alt="download"
                v-else
              />
              <p>{{ $t("general.download") }}</p>
            </div>
            <div class="cc__cta--share">
              <div v-if="showAlert" class="credential_copy--alert">
                <img
                  src="@/assets/images/courseware/copy-check.svg"
                  alt="check"
                />
                <p>{{ $t("course.copied_to_clipboard") }}</p>
              </div>
              <div class="c-pointer credential_share" @click="copyUrl" v-else>
                <img
                  :src="require(`@/assets/images/my-achievements/share.svg`)"
                  alt="share"
                  width="20"
                  height="20"
                />
                <p class="credential__details-icon-text">
                  {{ $t("course.share") }}
                </p>
              </div>
            </div>
          </b-col>
          <!-- mobile UI -->
          <b-col cols="12" class="d-block d-lg-none" v-if="false">
            <b-sidebar
              v-model="certificateSideBar"
              id="certificate-sidebar"
              no-header
              width="100%"
            >
              <div class="certificate-sidebar-navbar">
                <div
                  class="certificate-sidebar-back-btn"
                  @click="$router.go(-1)"
                  v-if="isAuthenticated"
                >
                  <img
                    class="details-icons"
                    :src="require(`@/assets/images/my-achievements/back.svg`)"
                    alt="badge"
                    width="13"
                    height="17"
                  />
                </div>
                <div class="certificate-sidebar-title">
                  {{ this.getSelectedCourse.title }}
                </div>
                <!-- TODO:: Hide the download and share button for Share URL,
                when updating the mobile responsive pages below -->
                <div class="credential__card-operation">
                  <div class="certificate-sidebar-download-btn">
                    <img
                      :src="
                        require(`@/assets/images/my-achievements/download.svg`)
                      "
                      alt="download"
                    />
                  </div>
                  <div class="certificate-sidebar-share-btn" @click="copyUrl">
                    <img
                      :src="
                        require(`@/assets/images/my-achievements/share.svg`)
                      "
                      alt="share"
                      width="18"
                      height="20"
                    />
                  </div>
                </div>
              </div>
              <Certificate v-if="awardType === 'certificate'" />
              <Credential
                v-if="awardType === 'credential'"
                :showError="showError"
                :courseCredential="courseCredential"
              ></Credential>
            </b-sidebar>
          </b-col>
        </b-row>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpBreadcrumb, LxpLoader } from "didactica";
import LayoutManager from "../Layout/LayoutManager.vue";
// components
import Certificate from "./Certificate.vue";
import ConsolidatedCertificate from "./ConsolidatedCertificate.vue";
import Credential from "../Certificate/CredentialCard.vue";
// mixins
import DownloadShareMixin from "../Achievements/mixins/downloadShare";
export default {
  mixins: [DownloadShareMixin],
  components: {
    LxpBreadcrumb,
    LxpLoader,
    LayoutManager,
    Certificate,
    ConsolidatedCertificate,
    Credential
  },
  data() {
    return {
      showError: false,
      showAlert: false,
      isLoading: true,
      certificateSideBar: true,
      breadcrumbs: [
        {
          icon: require("@/assets/images/courseware/school-grey.svg"),
          text: this.$i18n.t("general.menu.my_learning"),
          to: { name: "my-learning" }
        },
        {
          icon: null,
          text: this.$i18n.t("my_achievements.old_certificates.title"),
          to: { name: "legacy-achievements" }
        }
      ],
      discoveryBreadcrumbs: [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "course-discovery" }
        }
      ],
      courseDetailBreadcrumbs: [
        {
          icon: require("@/assets/images/courseware/school-grey.svg"),
          text: this.$i18n.t("general.menu.my_learning"),
          to: { name: "my-learning" }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["language"]),
    awardType() {
      return this.$route.params.type;
    },
    credentialId() {
      // isShareAward in the `downloadShare.js` mixin
      return this.isShareAward ? this.$route.params.id : this.$route.query.id;
    },
    setBreadcrumbsPath() {
      if (this.$route.query.from === "discovery") {
        return this.discoveryBreadcrumbs;
      } else if (this.$route.query.from === "course-detail") {
        return this.courseDetailBreadcrumbs;
      } else {
        return this.breadcrumbs;
      }
    }
  },
  created() {
    this.$emit("update:layout", "main");
    if (this.$route.query.lang) {
      if (this.allLanguage?.find(l => l.id === this.$route.query.lang)) {
        this.language = this.$route.query.lang;
      }
    }
  },
  mounted() {
    if (this.awardType === "credential") {
      this.getCredentialDetail();
    } else {
      if (
        this.awardType === "certificate" &&
        Object.keys(this.certificateDetail).length === 0
      ) {
        this.getCertificateDetail();
      } else if (this.awardType === "consolidated") {
        this.getCertificateDetail(true);
      }
      this.setBredcrumbVal();
      this.isLoading = false;
    }
    setTimeout(() => {
      this.$i18n.locale = this.language;
      this.$store.commit("SET_LANGUAGE", this.language);
    }, 50);
  },
  methods: {
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    copyUrl() {
      let url = "";
      if (this.awardType === "certificate") {
        url = this.certificateDetail.share_url_certificate;
      } else if (this.awardType === "credential") {
        url = this.courseCredential.share_url;
      } else if (this.awardType === "consolidated") {
        url = this.certificateDetail.share_url_consolidated;
      }

      var input = document.createElement("input");
      input.setAttribute("value", url);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
    setBredcrumbVal() {
      this.breadcrumbs.push(
        {
          icon: null,
          text: this.certificateDetail.course_key_string,
          to: `/coursewares/${this.certificateDetail.course_key_string}`
        },
        {
          icon: null,
          text: "Credential",
          active: true
        }
      );
    },
    courseTitleClick() {
      let id;
      this.awardType === "credential"
        ? (id = this.courseCredential.course_id)
        : (id = this.certificateDetail.course_id);
      this.navigateTo(`/coursewares/${id}`);
    },
    goBack() {
      if (this.$route.name === "Awards") {
        this.navigateTo(`/coursewares/${this.courseId}`);
      } else {
        this.$router.go(-1);
      }
    },
    onDownload() {
      if (this.awardType === "certificate") {
        this.downloadCertificate();
      } else {
        this.downloadCredential();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.certificate-wrapper {
  margin: 40px 0 0;
  .certificate-wrapper--div {
    &.is-public {
      .cw-main {
        margin: 16px auto;
        font-family: $font-family;
        .cw-main__heading {
          label {
            @include body-regular($brand-neutral-300);
          }
          h3 {
            @include h5($brand-neutral-700);
            cursor: pointer;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
  .credential__breadcrumb--margin {
    padding: 0 86px 12px;
  }
  .credential__card-operation {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
    .cc__cta--share,
    .cc__cta--download {
      display: flex;
      margin-bottom: 16px;
      cursor: pointer;
      .credential_share,
      .credential_copy--alert {
        display: flex;
      }
      .credential_copy--alert {
        p {
          color: $brand-neutral-800;
        }
      }
      p {
        margin: 0 0 0 8px;
        @include label-large($brand-primary);
      }
    }
    &.disabled {
      pointer-events: none;
      p {
        color: $brand-neutral-300;
      }
      img {
        filter: invert(72%) sepia(0%) saturate(11%) hue-rotate(17deg)
          brightness(90%) contrast(87%);
      }
    }
  }
}
</style>
